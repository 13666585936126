#users_page {

  .memo{
    width: 100%;

    .block-ellipsis {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      height: 43px;
      margin: 0 auto;
      font-size: 14px;
      line-height: 1;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      word-break: break-all
    }
    .memo-group-button{
      display: flex;
      justify-content: space-around;
      margin: 5px 0px;
    }
  }

  select {
		outline-offset: 0px !important;
		&.under_reservation {
			background-color: #FFFFFF;
			border: 2px solid #647cff;
			border-radius: 4px;
			height: 40px;
			line-height: 20px;
			padding: 8px 10px;
			cursor: pointer;
			color: #000000;
		}
		&.treatment_complete {
			background-color: #FFFFFF;
			border: 2px solid #2196f3;
			border-radius: 4px;
			height: 40px;
			line-height: 20px;
			padding: 8px 10px;
			cursor: pointer;
			color: #000000;
		}
		&.cancel_reservation {
			background-color: #FFFFFF;
			border: 2px solid red;
			border-radius: 4px;
			height: 40px;
			line-height: 20px;
			padding: 8px 10px;
			cursor: pointer;
			color: #000000;
		}

		&:focus {
			outline-offset: 0px !important;
		}
	}
  
  .makeStyles-clickableAppointment-308 {
    overflow: initial;
  }

  .button-back {
    margin-bottom: 25px;
  }

  .user_detail {
    padding: 20px;
    max-width: 100%;
    margin: auto;
    .item {
      margin-bottom: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 25px;
      .item_title {
        font-weight: 600;
        padding-right: 8px;
      }
      .list_practitioner_info {
        list-style-type: none;
        padding: 0px;

        > li {
          margin-bottom: 5px;
        }
      }
    }
    select {
      width: 200px;
      max-width: 100%;
      height: 44px;
      padding: 7px 15px;
      font-size: 16px;
    }
  }
}
