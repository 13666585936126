#reservations_page {

  .memo{
    width: 100%;
    min-width: 200px;

    .block-ellipsis {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      height: 43px;
      margin: 0 auto;
      font-size: 14px;
      line-height: 1;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      word-break: break-all
    }
    .memo-group-button{
      display: flex;
      justify-content: space-around;
      margin: 5px 0px;
    }
  }

	select {
		outline-offset: 0px !important;
		&.under_reservation {
			background-color: #FFFFFF;
			border: 2px solid #647cff;
			border-radius: 4px;
			height: 40px;
			line-height: 20px;
			padding: 8px 10px;
			cursor: pointer;
			color: #000000;
		}
		&.treatment_complete {
			background-color: #FFFFFF;
			border: 2px solid #2196f3;
			border-radius: 4px;
			height: 40px;
			line-height: 20px;
			padding: 8px 10px;
			cursor: pointer;
			color: #000000;
		}
		&.cancel_reservation {
			background-color: #FFFFFF;
			border: 2px solid red;
			border-radius: 4px;
			height: 40px;
			line-height: 20px;
			padding: 8px 10px;
			cursor: pointer;
			color: #000000;
		}

		&:focus {
			outline-offset: 0px !important;
		}
	}

	.list_practitioner_info {
	    list-style-type: none;
	    padding: 0px;

	    >li {
	    	margin-bottom: 5px;
	    }
	}

  .formik_form {
    padding: 20px;
    width: 535px;
    max-width: 100%;
    margin: auto;

    .group_input_name {
      margin-bottom: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);;
      padding-bottom: 25px;

      .title {
        font-size: 16px;
        line-height: 20px;
        display: block;
        width: 100%;
        margin-bottom: 5px;
      }

      input {
        width: 500px;
        max-width: 100%;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 15px;
      }

      .upload-file {
        padding: 10px 0px;
        width: auto;
      }

      .display-nome {
        display: none;
      }

      .img-show-file {
        margin-right: 15px;
        float: left;
      }

      select {
        width: 200px;
        max-width: 100%;
        height: 44px;
        padding: 7px 15px;
        font-size: 16px;
      }

      .item_menu {
        width: 100%;
        margin-bottom: 10px;
        display: block;

        input {
          width: auto;
          padding: 0px;
        }
      }

      .link_to_image {
        width: auto;
        padding: 0px;
      }
    }
    .datetime-control {
      max-width: 230px;
    }
  }
  
  .MuiOutlinedInput-input{
    height: 24px;
  }

  .reserved_times {
  }
  .reserved_time {
    background-color: yellow;
    display: inline-block;
    padding: 10px;
    margin: 10px 10px 5px 0;
    background-color: #C69F5E;
    color: white;
    cursor: pointer;
    text-align: center;
    border: 1px solid white;
    &.active {
      background-color: white;
      color: #C69F5E;
      border: 1px solid #C69F5E;
    }
  }

}

.dialog_confirm {

	.group_botton {
		justify-content: center;
	}
}
