#prices_page {

  .makeStyles-clickableAppointment-308 {
    overflow: initial;
  }

  .button-back {
    margin-bottom: 25px;
  }

  .showtime-preview {
    color: #ffffff;
    padding: 0px 0px;
    height: 100%;
    overflow-y: auto;

    .list_reservations {
      margin: 0px;
      padding: 0px;
      list-style: none;
      position: relative;
      width: 100%;
      height: calc(100%);

      >li {
        position: absolute;
        width: 100%;


        >.sub_list {
          list-style: none;
          padding: 0px;
          display: flex;
          width: 100%;
          overflow-y: auto;

          >li {
            width: 100%;
            float: left;
            display: block;
            // min-width: 65px;

            .btn-tooltip {
              display: block;
              width: 100%;
              background-color: #bae9c1;
              font-size: 12px;
              font-weight: 400;
              text-align: left;
              color: #3e3e3e;
              border-radius: 0px;
              border-left: 2px solid #51c2f4;
              font-size: 10px;
              line-height: 16px;
              padding: 5px 0px 2px 5px;

              .name {
                display: block;
                text-transform: initial;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            &.item-cancel-reservation {

              .btn-tooltip {
                background-color: #e1d7da;
                color: #3e3e3e;
                border-color: #797979;
              }
            }

            &.item-done {

              .btn-tooltip {
                background-color: #cfe3f4;
                color: #3e3e3e;
                border-color: #2295f1;
              }
            }
          }
        }
      }
    }
  }

  .showtime-reservations-admin {
    position: relative;
    width: calc(100%);
    height: calc(100%);
    padding: 10px 5px;
    color: #ffffff;
    background-color: #4f4f4f;
    pointer-events: none;
    cursor: not-allowed;

    .line {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }

  .formik_prices_create {
    padding: 20px;
    width: 535px;
    max-width: 100%;
    margin: auto;

    .group_input_name {
      margin-bottom: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);;
      padding-bottom: 25px;

      .title {
        font-size: 16px;
        line-height: 20px;
        display: block;
        width: 100%;
        margin-bottom: 5px;
      }

      input {
        width: 500px;
        max-width: 100%;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 15px;
      }

      .upload-file {
        padding: 10px 0px;
        width: auto;
      }

      .display-nome {
        display: none;
      }

      .img-show-file {
        margin-right: 15px;
        float: left;
      }

      select {
        width: 200px;
        max-width: 100%;
        height: 44px;
        padding: 7px 15px;
        font-size: 16px;
      }

      .item_menu {
        width: 100%;
        margin-bottom: 10px;
        display: block;

        input {
          width: auto;
          padding: 0px;
        }
      }

      .link_to_image {
        width: auto;
        padding: 0px;
      }
    }
    .datetime-control {
      max-width: 260px;
    }
  }
}
