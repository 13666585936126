.right_content_page {
    margin-top: 15px;

    .header_right_content_page {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: inherit;
        padding: 16px;
        
        .title_header_right_content_page { 
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            margin-top: 2px;
        }
        .btn_create {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }

    .error {
        display: contents;
        width: 100%;
        font-size: 12px;
        color: #ff0000;
        line-height: 17px;
    }

    .min_100 {
        min-width: 100px;
    }

    .min_110 {
        min-width: 110px;
    }

    .min_120 {
        min-width: 120px;
    }
}