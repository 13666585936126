#practitioners_page {

	.makeStyles-clickableAppointment-308 {
		overflow: initial;
	}

	.button-back {
		margin-bottom: 25px;
	}
	.MuiDrawer-paper{
		width: 75%;
	}
	.showtime-preview {
		color: #ffffff;
	    padding: 0px 0px;
	    height: 100%;
	    overflow-y: auto;
	    // padding-bottom: 15px;

		.list_reservations {
			margin: 0px;
			padding: 0px;
			list-style: none;
			position: relative;
			width: 100%;
    		height: calc(100%);

			>li {
			    position: absolute;
			    width: 100%;

			    
			    >.sub_list {
    				list-style: none;
    				padding: 0px;
    				display: flex;
    				width: 100%;
    				// overflow-y: auto;
					overflow: hidden;

    				>li {
    					width: 100%;
    					float: left;
    					display: block;
    					// min-width: 65px;

	    				.btn-tooltip {
	    					display: block;
						    width: 100%;
						    // min-height: 88px;
						    // font-size: 12px;
						    font-weight: 400;
						    text-align: left;
						    color: #3e3e3e;
						    border-radius: 0px;
						    border-left: 2px solid #51c2f4;
						    font-size: 10px;
    						line-height: 16px;
    						padding: 5px 0px 2px 5px;

    						.name {
    							display: block;
							    text-transform: initial;
							    text-overflow: ellipsis;
							    white-space: nowrap;
							    overflow: hidden;
    						}
	    				}

	    				&.item-cancel-reservation {

	    					.btn-tooltip {
								background-color: #e1d7da;
								color: #3e3e3e;
								// border-color: #797979; 
	    					}
	    				}

	    				&.item-done {

	    					.btn-tooltip {
								background-color: #cfe3f4;
								color: #3e3e3e;
								// border-color: #2295f1; 
	    					}
	    				}

						&.item-doing {

	    					.btn-tooltip {
								background-color: #bae9c1;
								color: #3e3e3e;
								// border-color: #51c2f4; 
	    					}
	    				}
    				}
			    }
			}
		}
	}
	.practitioner_detail {
		padding: 20px;
		max-width: 100%;
		margin: auto;
		.item {
		  margin-bottom: 25px;
		  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		  padding-bottom: 25px;
		  .item_title {
			font-weight: 600;
			padding-right: 8px;
		  }
		}
	}
	.showtime-reservations-admin {
		position: relative;
		width: calc(100%);
		height: calc(100%);
		padding: 10px 5px;
		color: #ffffff;
		background-color: #4f4f4f;
	    pointer-events: none;
	    cursor: not-allowed;

		.line {
			display: block;
			width: 100%;
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;

		}
	}

	.formik_practitioners_create {
		padding: 20px;
	    width: 535px;
	    max-width: 100%;
	    margin: auto;

		.group_input_name {
			margin-bottom: 25px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);;
			padding-bottom: 25px;

			.primary {
				margin: 5px;
				position: relative;
				width: 40px;
				height: 40px;
				cursor: pointer;
			  }
			  .secondary {
				position: absolute;
				top: 0; bottom: 0; right: 0; left: 0;
				opacity: 0;
				font-size: 10px;
				background: white;
				color: black;
				transition: 0.4s ease-out;
				text-align: center;
			  }
			  .primary:hover {
				.secondary {
				  opacity: 0.6;
				}
			  }
			
			.title {
    			font-size: 16px;
				line-height: 20px;
				display: block;
				width: 100%;
				margin-bottom: 5px;
			}

			input {
				width: 500px;
				max-width: 100%;
				font-size: 14px;
				line-height: 20px;
				padding: 10px 15px;
			}

			.upload-file {
			    padding: 10px 0px;
			    width: auto;
			}

			.display-nome {
				display: none;
			}

			.img-show-file {
				margin-right: 15px;
				float: left;
			}

			select {
				width: 200px;
				max-width: 100%;
				height: 44px;
				padding: 7px 15px;
				font-size: 16px;
			}

			.item_menu {
				width: 100%;
				margin-bottom: 10px;
    			display: block;

				input {
				    width: auto;
				    padding: 0px;
				}
			}

			.link_to_image {
				width: auto;
    			padding: 0px;
			}

			.MuiFormControl-root{
				> div{
					> div{
						margin: 0;
						padding: 0;

						.MuiChip-root{
							margin: 4px;
						}
					}
				}
			}
		}
	}
}